const task_check = () => {
  const tasks = document.querySelectorAll('#task');
  Array.from(tasks).forEach((task) => {
    task.addEventListener("click", function (event) {
      if (task.className == "fas fa-check") {
        task.className = "far fa-square";
      } else {
        task.className = "fas fa-check";
      }
    });
  });
}

export { task_check };
